import React from 'react'
import PageLayout from '../templates/page-layout'
import 'antd/dist/antd.css';
import { Card, Row, Col } from 'antd';
import { Link } from 'gatsby';
import { Theme } from '../styles/theme';

const ProjectsPage = ({
    data
}) => {
    const projects = data.allMarkdownRemark.edges.map(item => {
        return item.node
    }).sort((a, b) => {
      if (a.frontmatter.status < b.frontmatter.status) {
        return 1
      }

      return -1
    })

    return (
        <PageLayout>
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <h1>Our Projects</h1>
                <Row gutter={[16, 16]}>
                {projects.map((project, index) => {
                    return (
                        <Col xs={24} sm={12} lg={8} key={index}>
                            <Card hoverable actions={[<Link to={project.fields.slug}>View Developments</Link>]} cover={(
                              <div style={{position: 'relative'}}>
                                <img alt={project.frontmatter.title} style={{height: 200, width: '100%'}} src={project.frontmatter.image} />
                                <div style={{background: project.frontmatter.status == 'closed' ? Theme.secondaryColor : 'green', paddingTop: 5, paddingRight: 10, paddingLeft: 10, paddingBottom: 5, color: 'white', fontSize: '0.8em',
                                    position: 'absolute', right: 0, bottom: 0}}>
                                  {project.frontmatter.status == 'open' ? 'Open for Investment' : 'Finalized'}
                                </div>
                              </div>
                            )}>
                                <Card.Meta title={project.frontmatter.title} description={<div style={{minHeight: 150}}>{project.excerpt}<br/><div style={{marginTop: 10, fontWeight: 'bold'}}>{project.frontmatter.investment}</div></div>} />
                            </Card>
                        </Col>
                    )
                })}
                </Row>
            </div>
        </PageLayout>
    )
}

export default ProjectsPage

export const query = graphql`
  query GetProjects {
    allMarkdownRemark(filter: {fields: {type: {eq: "project"}}}) {
        edges {
          node {
            fields {
              type
              slug
            }
            frontmatter {
              title
              image
              investment
              status
            }
            excerpt(format: PLAIN)
          }
        }
      }
  }
`